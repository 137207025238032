/* eslint-disable @typescript-eslint/no-explicit-any */
import { type FC } from "react";
import { type LayoutProps } from "~/foundation/Multisite";
import { useParams } from "react-router-dom";
import RouteHandler from "./RouteHandler";

// support languages in the URL prefix
// e.g. /da-DK/path, or /en/path, or /path
const LANGUAGE_REG_EXP = /^\/?(([a-z]{2}-[A-Z]{2})|([a-z]{2}))(\/|$)/g;

export const parseRouteParams = (url: string) => {
	const language = url.match(LANGUAGE_REG_EXP);
	const route = url.replace(LANGUAGE_REG_EXP, '');

	return {
		route: route.startsWith('/') ? route : `/${route}`,
		language: language ? language[0].replace(/\//g, '') : undefined,
	};
};

export type JssRouteProps = {
	isSSR: boolean;
	siteName: string;
	projectLayer: string;
	LayoutComponent: React.ComponentType<LayoutProps<any>>;
}

export const JssRoute: FC<JssRouteProps> = (props) => {
	const params = useParams();
	const url = params['*'] ?? "/";

	return <RouteHandler {...parseRouteParams(url)} url={url} isSSR={props.isSSR} siteName={props.siteName} projectLayer={props.projectLayer} LayoutComponent={props.LayoutComponent} />;
};